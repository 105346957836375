<template>
  <b-card bg-variant="Default">
    
    <!-- table -->
    <vue-good-table
    mode="remote"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    :totalRows="totalRecords"
    :rows="rows"
    :columns="columns"
    :isLoading.sync="isLoading"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    :pagination-options="{
        enabled: true,
        mode: 'pages',
        nextLabel: 'successiva',
        prevLabel: 'precedente',
        rowsPerPageLabel: 'righe per pagina',
        ofLabel: 'di',
        pageLabel: 'pagina', // for 'pages' mode
        perPage: 10
    }"
    styleClass="vgt-table">

      <div slot="emptystate"> <div class="text-center"> Nessun dato disponibile </div> </div>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Ragione sociale -->
        <div v-if="props.column.field === 'rag_soc'">
          <strong>{{ props.row.rag_soc }}</strong><br />
          <small>Codice cliente: <span class="font-italic">{{ props.row.codice }}</span></small>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <div class="text-nowrap">
            <feather-icon
                :id="`tabellaarticoli-riga-${props.row.id}-preview`"
                icon="InfoIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="apriPreview(props.row.id)"
            />
            <b-tooltip
                title="preview"
                class="cursor-pointer"
                :target="`tabellaarticoli-riga-${props.row.id}-preview`"
            />

            <feather-icon
                :id="`tabellaarticoli-riga-${props.row.id}-scheda`"
                icon="ClipboardIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="$router.push({ name: 'crm-azienda-scheda', params: { id_azienda: props.row.id }})"
            />
            <b-tooltip
                title="Scheda azienda"
                class="cursor-pointer"
                :target="`tabellaarticoli-riga-${props.row.id}-scheda`"
            />

            <feather-icon
                :id="`tabellaarticoli-riga-${props.row.id}-nuova-richiesta`"
                icon="BoxIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="$router.push({ name: 'crm-leads-nuovo', params: { id_azienda: props.row.id }})"
            />
            <b-tooltip
                title="Nuovo Ordine per questa azienda"
                class="cursor-pointer"
                :target="`tabellaarticoli-riga-${props.row.id}-nuova-richiesta`"
            />

            <feather-icon
                :id="`tabellaarticoli-riga-${props.row.id}-utenti`"
                icon="UserIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="$router.push({ name: 'crm-azienda-utenti', params: { id_azienda: props.row.id }})"
            />
            <b-tooltip
                title="Gestione Utenti"
                class="cursor-pointer"
                :target="`tabellaarticoli-riga-${props.row.id}-utenti`"
            />
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      
    </vue-good-table>

    <b-modal ref="my-modal" hide-footer centered title="Informazioni Articolo" modal-class="modal-primary" size="lg">
      <div class="d-block text-center">
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Tipo Azienda</b-col>
            <b-col md="8" lg="9">{{ infomodal.nome_tipo }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Ragione Sociale</b-col>
            <b-col md="8" lg="9">{{ infomodal.rag_soc }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">P.Iva</b-col>
            <b-col md="8" lg="9">{{ infomodal.piva }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">SDI</b-col>
            <b-col md="8" lg="9">{{ infomodal.sdi }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Indirizzo sede</b-col>
            <b-col md="8" lg="9">{{ infomodal.indirizzo }}<br />{{ infomodal.cap }} {{ infomodal.localita }} ({{ infomodal.provincia }})<br />{{ infomodal.paese }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Telefono</b-col>
            <b-col md="8" lg="9">{{ infomodal.telefono }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Email</b-col>
            <b-col md="8" lg="9">{{ infomodal.email }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Ultimo aggiornamento</b-col>
            <b-col md="8" lg="9">{{ infomodal.data_aggiornamento }}</b-col>
        </b-row>
      </div>
    </b-modal>

  </b-card>
  
</template>

<script>
import { BRow, BCol, BCard, BTooltip, BModal, VBModal } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    VueGoodTable,
    BTooltip,
    BModal, 
    VBModal,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      columns: [
        {
          label: 'Ragione Sociale',
          field: 'rag_soc',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'P.Iva',
          field: 'piva',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca per Prezzo',
          },
        },
        {
          label: 'Località',
          field: 'localita',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Operazioni',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: '',
            type: ''
            }
        ],
        page: 1, 
        perPage: 10,
        licenza_pubblica: 'testlicenza',
        id_azienda: 0
      },
      infomodal: {
        nome_tipo: '',
        rag_soc: '',
        codice: '',
        piva: '',
        sdi: '',
        indirizzo: '',
        cap: '',
        localita: '',
        provincia: '',
        paese: '',
        telefono: '',
        email: '',
        data_aggiornamento: '',
      },
      titolo_model_aggiungi: '',
      name: 0,
      nameState: null,
      id_articolo_passato: 0,
      id_azienda_passata: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    console.log('crm admin - lista aziende ....................')

    //this.rows = [];
    this.loadItems();
    
  },
  methods: {
    updateParams(newProps) {
      //console.log("updateParams --- vvv ")
      console.log(newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
        
        console.log("loadItems azienda ......");
        //console.dir(this.serverParams)
        //this.isLoading = true;

        this.updateParams({ id_utente: parseInt(this.userData.id_utente) })
        
        this.$http.get('v2/crmadmin/lista_aziende', {
            params: this.serverParams 
            }).then(response => { 
                /*
                console.log("risposta vvv"); 
                console.log(response.data)
                console.log('--- ---')
                console.dir(response.data)
                console.log("response.totalRecords -> "+response.data.totalRecords);
                */

                //console.log(response.data.rows)

                
                this.totalRecords = response.data[0].contarighe;
                this.rows = response.data[1].righe;
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;
            
            })  
  
    },

    apriPreview(id){
      console.log("click apriPreview ...... id -> "+id);

      this.$http.get('v2/crmadmin/singola_azienda/'+id)
        .then(response => { 
            console.log("risposta server --------")
            console.log(response.data)

            if(response.data.statusCode===200){
            //risposta positiva
                this.infomodal = { nome_tipo: response.data.dati.nome_tipo,
                codice: response.data.dati.codice,
                rag_soc: response.data.dati.rag_soc,
                piva: response.data.dati.piva,
                sdi: response.data.dati.sdi,
                indirizzo: response.data.dati.indirizzo,
                cap: response.data.dati.cap,
                localita: response.data.dati.localita,
                provincia: response.data.dati.provincia,
                paese: response.data.dati.paese,
                telefono: response.data.dati.telefono,
                email: response.data.dati.email,
                data_aggiornamento: response.data.dati.data_aggiornamento, };

                this.$refs['my-modal'].show()
            } else {
            //risposta negativa (errore sul server)
            
            }
        }).catch(e => {
            console.log(e);
        });
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
